@font-face {
    font-family: 'Circular Std';
    src: url('fonts/lineto-circular-book.woff2') format('woff2'),
         url('fonts/lineto-circular-book.woff') format('woff'),
         url('fonts/lineto-circular-book.eot'),
         url("fonts/lineto-circular-book.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url('fonts/lineto-circular-medium.woff2') format('woff2'),
         url('fonts/lineto-circular-medium.woff') format('woff'),
         url('fonts/lineto-circular-medium.eot'),
         url("fonts/lineto-circular-medium.eot?#iefix") format("embedded-opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src: url('fonts/lineto-circular-bold.woff2') format('woff2'),
         url('fonts/lineto-circular-bold.woff') format('woff'),
         url('fonts/lineto-circular-bold.eot'),
         url("fonts/lineto-circular-bold.eot?#iefix") format("embedded-opentype");
    font-weight: bold;
    font-style: normal;
}
