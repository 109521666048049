/*==============================
=            Layout            =
==============================*/

.row {
    width: 95%;

    @include breakpoint(medium only) {
        width: 90%;
    }

    @include breakpoint(xlarge) {
        width: 95%;
    }
}

.row .row {
    width: auto;
}

.row.expanded {
    width: 100%;
}

.section {
    padding: $space-6 0 0;

    @include breakpoint(medium only) {
        padding: $space-7 0 0;
    }

    @include breakpoint(large) {
        padding: $space-8 0 0;
    }
}

.section--pad-y {
    padding: $space-6 0;

    @include breakpoint(medium only) {
        padding: $space-7 0;
    }

    @include breakpoint(large) {
        padding: $space-8 0;
    }
}

.columns--align-right-shmedium {
    @include breakpoint(shmedium) {
        display: flex;
        justify-content: flex-end;
    }
}

.section--border-b {
    border-bottom: solid 1px $light-gray;
}

.divider--small {
    height: 1px;
    display: block;
    margin: $space-3 0;

    @include breakpoint(large) {
        margin: $space-4 0;
    }
}

.divider-line--small {
    height: 1px;
    display: block;
    margin: $space-3 0;
    background: $light-gray;

    @include breakpoint(large) {
        margin: $space-4 0;
    }
}


/*----------  Offset Sections  ----------*/

.offset__content {
    overflow: hidden;
}

.offset__text-column {
    display: flex;
    justify-content: flex-end;
}

.offset__text-block {
    max-width: rem-calc(587);

    @include breakpoint(xlarge) {
        padding-top: $space-5;
    }
}

.offset__images-1 {
    margin-bottom: $space-4;

    @include breakpoint(medium) {
        padding-left: $space-5;
        margin-bottom: $space-5;
    }

    @include breakpoint(xxlarge) {
        margin-right: rem-calc(-110);
        padding-left: rem-calc(100);
    }
}

.offset__images-2 {

    @include breakpoint(medium) {
        margin-top: -4vw;
    }

    @include breakpoint(xxlarge) {
        margin-left: rem-calc(-110);
        margin-top: rem-calc(-100);
    }
}

.offset__images-3 {

    @include breakpoint(xxlarge) {
        margin-left: rem-calc(-110);
    }
}

.offset__images-4 {
    @include breakpoint(xxlarge) {
        margin-right: rem-calc(-110);
    }
}

.offset__images-5 {
    @include breakpoint(xxlarge) {
        margin-right: rem-calc(-110);
    }
}


/*----------  Type Layouts  ----------*/

.section__divider {
    max-width: 100px;
    margin-bottom: rem-calc(60);
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.text-block {
    margin-bottom: $space-5;

    @include breakpoint(medium) {
        margin-bottom: $space-7;
    }

    @include breakpoint(large) {
        margin-bottom: $space-8;
    }
}

.text-block--medium {
    margin-bottom: $space-4;
    margin-top: $space-4;

    @include breakpoint(medium) {
        margin-bottom: $space-5;
        margin-top: 0;
    }
}

.text-block--small {
    padding-top: $space-3;
    padding-bottom: $space-3;

    @include breakpoint(xlarge) {
        padding-top: $space-4;
        padding-bottom: $space-4;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
}

.text-block--column {
    @include breakpoint(medium down) {
        padding-top: $space-5;
        padding-bottom: $space-4;
    }

    @include breakpoint(large) {
        padding-top: $space-4;
        padding-bottom: $space-4;
    }

}

.text-grid {

    .column,
    .columns {
        margin-bottom: $space-3;
    }

}

.content-grid {

    .column,
    .columns {
        @include breakpoint(small) {
            margin-bottom: $space-4;
        }

        @include breakpoint(medium) {
            margin-bottom: $space-5;
        }
    }
}

.content-grid--small {

    .column,
    .columns {

        @include breakpoint(medium) {
            margin-bottom: $space-4;
        }
    }
}


/*----------  Contact Page  ----------*/

.section__contact {

    @include breakpoint(xlarge down) {
        padding: $space-7 0;
    }

    @include breakpoint(medium down) {
        .columns:first-child,
        .column:first-child {
            margin-bottom: rem-calc(40);
        }
    }

    @include breakpoint(shmedium down) {
        padding: $space-6 0;
    }
}

.map-col-1 {
    @include breakpoint(xxlarge) {
        padding-left: 0;
    }
}

.map-col-2 {
    @include breakpoint(xxlarge) {
        padding-right: 0;
    }
}

.flex--stretch {
    display: flex;
    align-items: stretch;
}