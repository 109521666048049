/*==================================
=            Typography            =
==================================*/

// Mixins
@mixin title-size {
    font-size: $title-size-small;
    @include breakpoint(large) {
        font-size: $title-size;
    }
}

@mixin h1-size {
    font-size: $h1-size-small;
    @include breakpoint(large) {
        font-size: $h1-size;
    }
}

@mixin h2-size {
    font-size: $h2-size-small;
    @include breakpoint(large) {
        font-size: $h2-size;
    }
}

@mixin h3-size {
    font-size: $h3-size-small;
    @include breakpoint(large) {
        font-size: $h3-size;
    }
}

@mixin h4-size {
    font-size: $h4-size-small;
    @include breakpoint(large) {
        font-size: $h4-size;
    }
}

@mixin h5-size {
    font-size: $h5-size-small;
    @include breakpoint(large) {
        font-size: $h5-size;
    }
}

@mixin custom-size ($size, $small-size) {
    font-size: $small-size;
    @include breakpoint(large) {
        font-size: $size;
    }
}


/*----------  Added spacing for longform content  ----------*/

.page__entry > {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .label {
        margin: 2.5em 0 1.5em 0;
    }

    h3 {
        @include h4-size;
    }

    hr {
        margin: rem-calc(40) 0;
        @include breakpoint(large) {
            margin: rem-calc(60 0);
        }
    }

    .label {
        font-size: $h6-size;
    }


    *:first-child {
        margin-top: 0;
    }

}

.label {
    font-size: $small-size;
    font-family: $body-font-family;
    color: $primary-color;
    font-weight: 800;
    text-transform: uppercase;
    line-height: $global-lineheight;
}

address {
    font-style: normal;
    line-height: $paragraph-lineheight;

    a {
        color: $body-font-color;
    }
}

a:hover,
a:focus,
a:active {
    color: $primary-color;
}


/*----------  Custom Type Elements  ----------*/

.label {
    font-size: $h6-size;
    letter-spacing: rem-calc(2);
    display: block;
    margin-bottom: rem-calc(15);
}


.bullets--check {
    list-style: none;
    padding: 0;
    margin-left: 0;

    li {
        padding-left: rem-calc(25);
        position: relative;

        &:before {
            content: '\e91f';
            font-family: 'ehcp-icons';
            position: absolute;
            top: 0;
            left: 0;
            color: $primary-color;
        }
    }

}

.bullets {

    li {
        margin-bottom: $space-3;
    }
}


/*----------  Type Utilities  ----------*/

.text--center {text-align: center;}

.text--primary {color: $primary-color; }

.text--secondary {color: $secondary-color; }

.text--small {font-size: $small-size; }

.text--gray {color: $dark-gray;}

.text--white {color: $white; }

.text--bold {font-family: $global-weight-bold; }

.text--upper {text-transform: uppercase; }

.text--black { color: $black; }

.long-text {
    margin-right: rem-calc(-10);
}

strong {color: $black; font-weight: $global-weight-bold; }

.text-break--sm {
    @include breakpoint(small only) {
        display: block;
    }
}


/*----------  Responsive Type  ----------*/

html {

    @include breakpoint(medium down) {
        font-size: 90%;
    }

}