/*========================================
=            Page Transitions            =
========================================*/

.trans--fade {
    transition: opacity 0.8s ease;
    opacity: 1;
}

html.is-animating .trans--fade {
    opacity: 0;
}

/*----------  In View Image Fade  ----------*/

.no-js .hero__image img {
    opacity: 1;
}

.hero__image img {
    opacity: 0;
    transition: opacity .5s ease-in;

    &.has-loaded {
        opacity: 1;
    }
}

