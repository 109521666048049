/*=====================================
=            Locations Map            =
=====================================*/

#locationsMap {
    background: #EDEDED;
    display: flex;
    width: 100%;

    iframe {
        @include breakpoint(medium down) {
            height: rem-calc(400);
        }
    }
}

