/*===============================
=            Feature            =
===============================*/

/*----------  Feature Row  ----------*/

.feature__title {
    @include h1-size;
    margin-bottom: $space-4;
    letter-spacing: -0.5px;
}

.feature__summary {
    margin-bottom: $space-5;
}

.feature {
    @include breakpoint(medium) {
        .feature__image {
            padding-left: $space-5;
        }
    }
}

.feature--alt {
    @include breakpoint(medium) {
        & > .columns:first-child {
            order: 2;
        }

        .feature__image {
            padding-right: $space-5;
        }
    }
}

.feature__content {

    .button {
        margin-bottom: 0;
    }

    @include breakpoint(shmedium down) {
        margin-bottom: $space-5;
    }
}

/*----------  Feature Grid  ----------*/

.feature-grid {
    position: relative;

    @include breakpoint(medium) {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            right: auto;
            bottom: 0;
            width: 1px;
            background: $light-gray;
        }
    }
}

.feature-grid__item {

    @include breakpoint(shmedium down) {
        display: flex;
    }

    @include breakpoint(medium) {
        padding: 0 rem-calc(80) $space-5;
        text-align: center;
    }

    @include breakpoint(xlarge) {
        padding: 0 rem-calc(115) $space-6;
    }
}

.feature-grid__item {

    @include breakpoint(shmedium down) {
        margin-bottom: $space-4;
    }

    @include breakpoint(medium) {
        &:nth-child(4n+1),
        &:nth-child(4n+2) {
            border-bottom: solid 1px $light-gray;
        }

        &:nth-child(4n),
        &:nth-child(4n-1) {
            padding-top: $space-8;
        }
    }
}

.feature-grid__image {
    margin-bottom: $space-5;

    @include breakpoint(small only) {
        flex: 3;
    }

    @include breakpoint(shmedium only) {
        flex: 2;
    }

    img {
        display: block;
        margin: 0 auto;

        @include breakpoint(large) {
            max-height: rem-calc(225);
        }
    }
}

.feature-grid__content {
    @include breakpoint(shmedium down) {
        flex: 5;
        padding-left: $space-3;
        align-self: center;
    }

    @include breakpoint(shmedium only) {
        flex: 3;
        padding-left: $space-5;
    }
}

.feature-grid__title {
    margin-bottom: $space-2;
    @include h3-size;
}

.feature-grid__summary {
    line-height: $global-lineheight;
}
