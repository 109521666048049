/*=============================
=            Cards            =
=============================*/


.card-grid {

}

.card-grid__item {
    padding: $global-padding;
    border: solid 1px $light-gray;
    border-radius: $global-radius;
    margin-bottom: rem-calc(25);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(45);
    }
}

.card-grid__item--wide {
    padding: $global-padding $space-3;
}

.card-grid__icon,
.card-grid__image {

    img {
        display: block;
        margin: 0 auto;

    }
}

.card-grid__icon  {
    margin-bottom: $space-4;

    img {
        max-height: rem-calc(80);
    }
}

.card-grid__image {
    margin-bottom: $space-5;
    img {
        max-height: rem-calc(176);
    }
}


.card-grid__content {
    text-align: center;
}

.card-grid__title {
    @include custom-size($h4-size, $h3-size-small);
    margin-bottom: $space-3;
}

.card-grid__summary {
    font-size: $small-size;
    margin-bottom: $space-3;
}


/*----------  Press Assets  ----------*/

.press-asset {
    border: solid 1px $medium-gray;
    radius: $global-radius;
    margin-bottom: $space-4;

    @include breakpoint(large) {
        margin-bottom: $space-5;
    }
}

.press-asset__image {
    margin: 0;
    padding: rem-calc(40 20);
    text-align: center;

    @include breakpoint(medium) {
        padding: rem-calc(60 20);
    }

    img {
        max-width: rem-calc(200);
        width: 100%;
    }
}

.press-asset__links {
    border-top: solid 1px $medium-gray;

    a {
        padding: rem-calc(15);
        display: block;
        font-size: $h6-size;
        color: $dark-gray;
        @include global-transition(color);
    }

    a:hover {
        color: $primary-color;
    }
}

