/*============================
=            Hero            =
============================*/

.hero__title {
    @include title-size;
}

.hero--home {
    padding-bottom: $space-5;
    border-bottom: 1px solid $light-gray;

    .hero__content {
        text-align: center;
        margin-bottom: $space-6;

        @include breakpoint(large) {
            margin-bottom: $space-7;
        }
    }

    .hero__image {
        max-width: rem-calc(1300);
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        min-height: rem-calc(300);

        @include breakpoint(large only) {
            padding: 0 $space-4;
        }

        @include breakpoint(large) {
            min-height: rem-calc(390);
        }

        @include breakpoint(xlarge) {
            //max-width: rem-calc(1600);
        }

        img {
            @include breakpoint(shmedium down) {
                min-width: rem-calc(1000);
                margin-left: -10vh;
            }

            @include breakpoint(medium only) {
                margin-left: -10vw;
                margin-right: -10vw;
                max-width: 115%;
            }

        }

    }

}

.hero--inner {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);
    border-bottom: 1px solid $light-gray;

    .hero__content {

        .button {
            margin-bottom: 0;
        }

        @include breakpoint(shmedium down) {
            margin-bottom: $space-6;
        }
    }

    .hero__image {
        min-height: rem-calc(265);

        @include breakpoint(large) {
            min-height: rem-calc(490);
        }

        img {
            max-height: rem-calc(490);
            display: block;
            margin: 0 auto;
        }
    }
}

.hero--text {
    padding: $space-6 0;
    border-bottom: solid 1px $light-gray;

    @include breakpoint(large) {
        padding: $space-8 0;
    }
}
