/*==============================
=            Footer            =
==============================*/

.site-footer {
    border-top: 1px solid $light-gray;
    font-size: $small-size;
}

.site-footer__content {
    padding: $space-5 0;

    @include breakpoint(medium) {
        padding: $space-7 0;
    }
}

.contact-item__header {
    margin-bottom: 0;
    line-height: $header-lineheight;
}

.contact-item__link {
    @include h4-size;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.site-footer__logo {
    @include breakpoint(medium down) {
        margin-bottom: $space-4;
    }
}

.site-footer__bottom {
    border-top: 1px solid $light-gray;
    padding: rem-calc(30) 0;
    font-size: $h6-size;

    a {
        color: $body-font-color;
        @include global-transition(color);
        font-weight: $global-weight-normal;

        &:hover {
            color: $primary-color;
        }
    }
}

.site-footer__social-link {

    img {
        max-width: 24px;
        margin-right: 5px;
    }

    @include breakpoint(small only) {
        display: inline-block;
        margin-top: $space-4;
    }

    @include breakpoint(shmedium) {
        padding-right: rem-calc(30);
    }

}

.sitemap {
    display: flex;
    flex-grow: 3;

    @include breakpoint(shmedium down) {
        padding: $space-5 0 0 0;
    }

    @include breakpoint(xlarge) {
        padding-left: rem-calc(45);
    }
}

.sitemap__container {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.sitemap__menu {


    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ul li {
        margin-bottom: $space-3;
    }

    ul li a {
        color: $body-font-color;
        text-decoration: none;
        @include global-transition(color);
    }

    ul li a:hover {
        color: $primary-color;
    }
}

.contact-item,
.sitemap__container {

    @include breakpoint(large) {
        padding-top: rem-calc(10);
    }
}

.contact-item {
    padding-bottom: rem-calc(10);
}