/*===============================
=            Buttons            =
===============================*/

.button {
    font-weight: $global-weight-medium;
    min-width: 100px;

    span {
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
}

.button.hollow.primary {
    border: 1px solid $medium-gray;
}

.button.hollow.primary {
    &:hover {
        border: 1px solid $primary-color;
        background: $primary-color;
        color: $white;
    }
}
