/*===========================
=            CTA            =
===========================*/

.cta {
    border-top: solid 1px $light-gray;
}

.cta__title {
    @include h1-size;

    @include breakpoint(shmedium down) {
        margin-bottom: $space-4;
    }
}

.cta__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include breakpoint(small only) {
        flex-direction: column;
    }

    @include breakpoint(medium) {
        justify-content: flex-end;
    }

    a {
        margin-bottom: rem-calc(15);

        @include breakpoint(shmedium only) {
            width: 48%;
        }

        @include breakpoint(medium) {
            margin-bottom: 0;
            margin-right: rem-calc(25);
        }

        @include breakpoint(large) {
            min-width: rem-calc(180);
        }
    }
}

.cta--alt {
    .cta__title {
        @include breakpoint(shmedium down) {
            margin-bottom: $space-3;
        }
    }

    .cta__link-item {
        @include breakpoint(large) {
            flex-grow: 1;
        }
    }
}

.cta__summary {
    @include h4-size;
    margin-bottom: $space-4;
}

.cta__summary--alt {
    font-size: $global-font-size;
}

.cta__link-image {
    position: relative;
    max-width: rem-calc(80);
    margin-bottom: $space-4;
    margin-left: rem-calc(-3);

    @include breakpoint(shmedium down) {
        max-width: none;
        width: rem-calc(50);
        height: rem-calc(50);
    }
}

.cta__links--alt {
    @include breakpoint(large down) {
        margin-top: $space-3;
    }
}

.cta__link-content {
    @include breakpoint(shmedium down) {
        padding: 0 rem-calc(20);
    }
}

.cta__link-item {
    @include breakpoint(shmedium down) {
        display: flex;
    }

    .label {
        color: $black;
        margin-bottom: $space-1;

        @include breakpoint(shmedium down) {
            margin-bottom: rem-calc(3);
        }
    }

    a {
        @include h4-size;
    }
}
