/*=============================
=            Icons            =
=============================*/

@font-face {
  font-family: 'ehcp-icons';
  src:  url('fonts/ehcp-icons.eot?zha6cu');
  src:  url('fonts/ehcp-icons.eot?zha6cu#iefix') format('embedded-opentype'),
    url('fonts/ehcp-icons.ttf?zha6cu') format('truetype'),
    url('fonts/ehcp-icons.woff?zha6cu') format('woff'),
    url('fonts/ehcp-icons.svg?zha6cu#ehcp-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ehcp-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-circle:before {
  content: "\e900";
}
.icon-alert-octagon:before {
  content: "\e901";
}
.icon-alert-triangle:before {
  content: "\e902";
}
.icon-align-center:before {
  content: "\e903";
}
.icon-align-justify:before {
  content: "\e904";
}
.icon-align-left:before {
  content: "\e905";
}
.icon-align-right:before {
  content: "\e906";
}
.icon-archive:before {
  content: "\e907";
}
.icon-arrow-down-circle:before {
  content: "\e908";
}
.icon-arrow-down-left:before {
  content: "\e909";
}
.icon-arrow-down-right:before {
  content: "\e90a";
}
.icon-arrow-down:before {
  content: "\e90b";
}
.icon-arrow-left-circle:before {
  content: "\e90c";
}
.icon-arrow-left:before {
  content: "\e90d";
}
.icon-arrow-right-circle:before {
  content: "\e90e";
}
.icon-arrow-right:before {
  content: "\e90f";
}
.icon-arrow-up-circle:before {
  content: "\e910";
}
.icon-arrow-up-left:before {
  content: "\e911";
}
.icon-arrow-up-right:before {
  content: "\e912";
}
.icon-arrow-up:before {
  content: "\e913";
}
.icon-at-sign:before {
  content: "\e914";
}
.icon-award:before {
  content: "\e915";
}
.icon-bell-off:before {
  content: "\e916";
}
.icon-bell:before {
  content: "\e917";
}
.icon-book-open:before {
  content: "\e918";
}
.icon-book:before {
  content: "\e919";
}
.icon-bookmark:before {
  content: "\e91a";
}
.icon-box:before {
  content: "\e91b";
}
.icon-briefcase:before {
  content: "\e91c";
}
.icon-calendar:before {
  content: "\e91d";
}
.icon-check-circle-lrg:before {
  content: "\e91e";
}
.icon-check-circle:before {
  content: "\e91f";
}
.icon-check-square:before {
  content: "\e920";
}
.icon-check:before {
  content: "\e921";
}
.icon-chevron-down:before {
  content: "\e922";
}
.icon-chevron-left:before {
  content: "\e923";
}
.icon-chevron-right:before {
  content: "\e924";
}
.icon-chevron-up:before {
  content: "\e925";
}
.icon-chevrons-down:before {
  content: "\e926";
}
.icon-chevrons-left:before {
  content: "\e927";
}
.icon-chevrons-right:before {
  content: "\e928";
}
.icon-chevrons-up:before {
  content: "\e929";
}
.icon-circle:before {
  content: "\e92a";
}
.icon-clipboard:before {
  content: "\e92b";
}
.icon-clock:before {
  content: "\e92c";
}
.icon-code:before {
  content: "\e92d";
}
.icon-command:before {
  content: "\e92e";
}
.icon-compass:before {
  content: "\e92f";
}
.icon-copy:before {
  content: "\e930";
}
.icon-corner-down-left:before {
  content: "\e931";
}
.icon-corner-down-right:before {
  content: "\e932";
}
.icon-corner-left-down:before {
  content: "\e933";
}
.icon-corner-left-up:before {
  content: "\e934";
}
.icon-corner-right-down:before {
  content: "\e935";
}
.icon-corner-right-up:before {
  content: "\e936";
}
.icon-corner-up-left:before {
  content: "\e937";
}
.icon-corner-up-right:before {
  content: "\e938";
}
.icon-credit-card:before {
  content: "\e939";
}
.icon-delete:before {
  content: "\e93a";
}
.icon-disc:before {
  content: "\e93b";
}
.icon-dollar-sign:before {
  content: "\e93c";
}
.icon-download-cloud:before {
  content: "\e93d";
}
.icon-download:before {
  content: "\e93e";
}
.icon-edit-2:before {
  content: "\e93f";
}
.icon-edit-3:before {
  content: "\e940";
}
.icon-edit:before {
  content: "\e941";
}
.icon-external-link:before {
  content: "\e942";
}
.icon-eye-off:before {
  content: "\e943";
}
.icon-eye:before {
  content: "\e944";
}
.icon-file-minus:before {
  content: "\e945";
}
.icon-file-plus:before {
  content: "\e946";
}
.icon-file-text:before {
  content: "\e947";
}
.icon-file:before {
  content: "\e948";
}
.icon-film:before {
  content: "\e949";
}
.icon-filter:before {
  content: "\e94a";
}
.icon-flag:before {
  content: "\e94b";
}
.icon-globe:before {
  content: "\e94c";
}
.icon-grid:before {
  content: "\e94d";
}
.icon-hash:before {
  content: "\e94e";
}
.icon-heart:before {
  content: "\e94f";
}
.icon-help-circle:before {
  content: "\e950";
}
.icon-home:before {
  content: "\e951";
}
.icon-image:before {
  content: "\e952";
}
.icon-inbox:before {
  content: "\e953";
}
.icon-info:before {
  content: "\e954";
}
.icon-layers:before {
  content: "\e955";
}
.icon-layout:before {
  content: "\e956";
}
.icon-life-buoy:before {
  content: "\e957";
}
.icon-link-2:before {
  content: "\e958";
}
.icon-link:before {
  content: "\e959";
}
.icon-list:before {
  content: "\e95a";
}
.icon-loader:before {
  content: "\e95b";
}
.icon-lock:before {
  content: "\e95c";
}
.icon-log-in:before {
  content: "\e95d";
}
.icon-log-out:before {
  content: "\e95e";
}
.icon-mail:before {
  content: "\e95f";
}
.icon-map-pin:before {
  content: "\e960";
}
.icon-map:before {
  content: "\e961";
}
.icon-maximize-2:before {
  content: "\e962";
}
.icon-maximize:before {
  content: "\e963";
}
.icon-menu:before {
  content: "\e964";
}
.icon-message-circle:before {
  content: "\e965";
}
.icon-message-square:before {
  content: "\e966";
}
.icon-minimize-2:before {
  content: "\e967";
}
.icon-minimize:before {
  content: "\e968";
}
.icon-minus-circle:before {
  content: "\e969";
}
.icon-minus-square:before {
  content: "\e96a";
}
.icon-minus:before {
  content: "\e96b";
}
.icon-monitor:before {
  content: "\e96c";
}
.icon-more-horizontal:before {
  content: "\e96d";
}
.icon-more-vertical:before {
  content: "\e96e";
}
.icon-navigation-2:before {
  content: "\e96f";
}
.icon-navigation:before {
  content: "\e970";
}
.icon-package:before {
  content: "\e971";
}
.icon-phone-call:before {
  content: "\e972";
}
.icon-phone-forwarded:before {
  content: "\e973";
}
.icon-phone-incoming:before {
  content: "\e974";
}
.icon-phone-missed:before {
  content: "\e975";
}
.icon-phone-off:before {
  content: "\e976";
}
.icon-phone-outgoing:before {
  content: "\e977";
}
.icon-phone:before {
  content: "\e978";
}
.icon-plus-circle:before {
  content: "\e979";
}
.icon-plus-square:before {
  content: "\e97a";
}
.icon-plus:before {
  content: "\e97b";
}
.icon-printer:before {
  content: "\e97c";
}
.icon-refresh-ccw:before {
  content: "\e97d";
}
.icon-refresh-cw:before {
  content: "\e97e";
}
.icon-repeat:before {
  content: "\e97f";
}
.icon-rotate-ccw:before {
  content: "\e980";
}
.icon-rotate-cw:before {
  content: "\e981";
}
.icon-search:before {
  content: "\e982";
}
.icon-send:before {
  content: "\e983";
}
.icon-settings:before {
  content: "\e984";
}
.icon-share:before {
  content: "\e985";
}
.icon-shield-off:before {
  content: "\e986";
}
.icon-shield:before {
  content: "\e987";
}
.icon-shopping-bag:before {
  content: "\e988";
}
.icon-shopping-cart:before {
  content: "\e989";
}
.icon-sliders:before {
  content: "\e98a";
}
.icon-tablet:before {
  content: "\e98b";
}
.icon-tag:before {
  content: "\e98c";
}
.icon-toggle-left:before {
  content: "\e98d";
}
.icon-toggle-right:before {
  content: "\e98e";
}
.icon-trash-2:before {
  content: "\e98f";
}
.icon-trash:before {
  content: "\e990";
}
.icon-trending-down:before {
  content: "\e991";
}
.icon-trending-up:before {
  content: "\e992";
}
.icon-triangle:before {
  content: "\e993";
}
.icon-truck:before {
  content: "\e994";
}
.icon-upload-cloud:before {
  content: "\e995";
}
.icon-upload:before {
  content: "\e996";
}
.icon-user:before {
  content: "\e997";
}
.icon-users:before {
  content: "\e998";
}
.icon-x-circle:before {
  content: "\e999";
}
.icon-x-square:before {
  content: "\e99a";
}
.icon-x:before {
  content: "\e99b";
}
