/*==============================
=            Header            =
==============================*/

// Variables
$header-height-lrg: 90px;
$header-height-sm: 60px;

.site-header {
    background: $white;
    display: flex;
    position: relative;
    z-index: 9999;

    &:after {
        content: '';
        position: absolute;
        z-index: 9999;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: $light-gray;
    }
}

.site-logo {
    display: flex;
    margin: 0;
    opacity: 1;
    position: relative;
    z-index: 9999;
    @include global-transition;

    &:hover {
        opacity: 0.6;
    }

    img {
        align-self: center;
    }
}

.site-logo a {
    display: block;
}

.site-nav {
    ul {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul > li {
        margin: 0;
        padding: 0;
    }

    ul > li.selected > a {
        position: relative;

        @include breakpoint(large) {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: rem-calc(15);
                right: rem-calc(15);
                height: 2px;
                z-index: 99999;
                background: $primary-color;
            }
        }

    }

    ul > li > a {
        font-weight: $global-weight-normal;
        font-family: $body-font-family;
        text-decoration: none;
        color: $dark-gray;
        font-size: $small-size;
        display: block;
        @include global-transition;

        &:hover {
            color: $primary-color;
        }

    }

    ul li a span {
        display: inline-block;
        margin-right: 5px;
        color: $primary-color;
    }
}


/*-----------Drawer Toggle-----------*/
.nav-toggle {
    @include hide-for(large);
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    outline: none;
    z-index: 9999;
    width: $header-height-sm + 10px;
    height: $header-height-sm;
    transition: background 0.2s ease-in-out;
}

.nav-toggle span {
    position: absolute;
    display: block;
    width: 23px;
    height: 2px;
    background: $dark-gray;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -12px;
}
.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
    transition: transform 0.2s ease-in-out;
}
.nav-toggle span::before,
.nav-toggle span::after {
    content: '';
    position: absolute;
    left: 0;
    background: inherit;
    width: 100%;
    height: 100%;
}
.nav-toggle span::before {
    top: -6px;
}
.nav-toggle span::after {
    bottom: -6px;
}
body.nav--open .nav-toggle {
    background: transparent;

    span {
        transform: rotate(45deg);
        background: $black;
        width: 20px;
    }
    span:before {
        top: 0;
        transform: rotate(-90deg);
    }
    span:after {
        bottom: 0;
    }
}


// Small Screens

@media screen and #{breakpoint(medium down)} {

    .site-header {
        height: $header-height-sm;
        align-items: stretch;
    }

    .site-header__container {
        display: flex;
        flex-grow: 2;
        //width: 100%;
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }

    .site-logo {
        padding-left: rem-calc(20);

        a {
            min-width: 110px;
        }
    }

    .nav-block {
        position: fixed;
        z-index: 9998;
        top: $header-height-sm;
        left: 0;
        right: 0;
        bottom: 0;
        padding: rem-calc(60 80);
        overflow: scroll;

        @include breakpoint(shmedium down) {
            padding: rem-calc(60 30);
        }
    }

    .site-nav__main {
        flex-direction: row;

        ul {
            display: flex;
            flex-direction: column;
        }

        ul > li {
            margin-bottom: rem-calc(10);
        }

        ul > li > a {
            padding: rem-calc(15 0);
            @include h1-size;
            text-align: center;
            font-weight: $global-weight-medium;
            color: $black;
        }

        ul > li.selected > a {
            color: $primary-color;
        }

    }

    .nav-block__left {
        margin-bottom: rem-calc(50);
    }

    .nav-block__right {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        flex-wrap: wrap;
    }

    .site-nav__utility {
        display: flex;
        flex-grow: 1;
        order: 2;
    }

    .header-cta {
        width: 100%;
        order: 1;
        margin-bottom: rem-calc(20);
    }

    .site-nav__utility {
        display: flex;
        flex-direction: row;

        ul {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
        }

        ul > li {
            flex-grow: 1;
            text-align: center;
        }

        ul > li > a {
            padding: rem-calc(12 15);
            display: block;
        }

    }

    .header-cta__button {
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(20);
        width: 100%;
        margin: 0;
    }

    .nav-overlay {
        position: fixed;
        z-index: 9997;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $white;
    }

    /*----------  Nav States  ----------*/

    .nav-overlay {
        transform: translateY(-100%);
        opacity: 0;
        transition: opacity 0.2s ease-in, transform 0.2s ease-in;
    }
    .nav-block {
        transform: scale(0);
        transition: transform 0.4s ease-out;
        //transition-delay: .1s;
        opacity: 0;
    }

    body.nav--open {
        overflow: hidden;

        .nav-overlay {
            transform: translateY(0);
            opacity: 1;
        }
        .nav-block {
            transform: scale(1);
            opacity: 1;
        }

    }

    body.nav--closed {

        .nav-overlay {
            transform: translateY(-100%);
            transition-duration: 0.5s;
            transition-timing-function: ease-out;
            opacity: 0;
        }
        .nav-block {
            transform: scale(0);
            transition-delay: unset;
            transition-timing-function: ease-out;
        }

    }

}

// Large Screens

@media screen and #{breakpoint(large)} {

    .site-header {
        height: $header-height-lrg;
    }

    .site-header__container {
        display: flex;
        width: 95%;
        max-width: $global-width;
        margin: 0 auto;
        padding: 0 rem-calc(20);
        height: $header-height-lrg;
        flex-direction: row;
        justify-content: space-between;
    }

    .site-logo {
        width: 130px;
    }

    .nav-block {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        justify-content: space-between;
    }

    .nav-block__left,
    .nav-block__right {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .site-nav {
        display: flex;
        align-items: stretch;

        ul {
            display: flex;
            align-items: stretch;
        }

        ul > li {
            display: flex;
            align-items: stretch
        }

        ul > li > a {
            display: flex;
            align-items: center;
            padding: 0 rem-calc(20);
        }
    }

    .site-nav__main {
        padding-left: rem-calc(30);
    }

    .site-nav__utility {
        padding-right: rem-calc(10);
    }

    .header-cta {
        display: flex;
        align-self: center;
        border-left: solid 1px $light-gray;
        padding-left: rem-calc(30);
    }

    .header-cta__button {
        margin: 0;
    }

    .nav-overlay {
        display: none;
    }

}

// Medium Screens

@media screen and #{breakpoint(large only)} {

    .site-nav__main {
        padding-left: rem-calc(20);
    }

    .site-nav ul > li > a {
        padding: 0 rem-calc(15);
    }

}
