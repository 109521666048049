/*==============================
=            Mixins            =
==============================*/


/*----------  Transitions  ----------*/

@mixin global-transition($property: opacity) {
    transition: $property 0.3s ease-in-out;
}


/*----------  Animations  ----------*/

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}


/*----------  Grid  ----------*/

@mixin row-col() {
    margin: 0 auto;
    width: 95%;
    max-width: $global-width;
    padding-left: rem-calc(25) / 2;
    padding-right: rem-calc(25) / 2;

    @include breakpoint(medium only) {
        width: 90%;
    }

    @include breakpoint(large) {
        padding-left: rem-calc(45) / 2;
        padding-right: rem-calc(45) / 2;
    }
}

@mixin site-row() {
    margin: 0 auto;
    width: 95%;
    max-width: $global-width;

    @include breakpoint(medium only) {
        width: 90%;
    }

}

@mixin site-row-nested() {
    margin: 0 auto;
    width: auto;
    max-width: $global-width;
    margin-left: rem-calc(-25) / 2;
    margin-right: rem-calc(-25) / 2;

    @include breakpoint(large) {
        margin-left: rem-calc(-45) / 2;
        margin-right: rem-calc(-45) / 2;
    }

}

