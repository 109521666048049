/*===================================
=            White Space            =
===================================*/

.m0  { margin:        0 !important}
.mt0 { margin-top:    0 !important}
.mr0 { margin-right:  0 !important}
.mb0 { margin-bottom: 0 !important}
.ml0 { margin-left:   0 !important}

.m1  { margin:        $space-1 !important}
.mt1 { margin-top:    $space-1 !important}
.mr1 { margin-right:  $space-1 !important}
.mb1 { margin-bottom: $space-1 !important}
.ml1 { margin-left:   $space-1 !important}

.m2  { margin:        $space-2 !important}
.mt2 { margin-top:    $space-2 !important}
.mr2 { margin-right:  $space-2 !important}
.mb2 { margin-bottom: $space-2 !important}
.ml2 { margin-left:   $space-2 !important}

.m3  { margin:        $space-3 !important}
.mt3 { margin-top:    $space-3 !important}
.mr3 { margin-right:  $space-3 !important}
.mb3 { margin-bottom: $space-3 !important}
.ml3 { margin-left:   $space-3 !important}

.m4  { margin:        $space-4 !important}
.mt4 { margin-top:    $space-4 !important}
.mr4 { margin-right:  $space-4 !important}
.mb4 { margin-bottom: $space-4 !important}
.ml4 { margin-left:   $space-4 !important}

.m5  { margin:        $space-5 !important}
.mt5 { margin-top:    $space-5 !important}
.mr5 { margin-right:  $space-5 !important}
.mb5 { margin-bottom: $space-5 !important}
.ml5 { margin-left:   $space-5 !important}

.m6  { margin:        $space-6 !important}
.mt6 { margin-top:    $space-6 !important}
.mr6 { margin-right:  $space-6 !important}
.mb6 { margin-bottom: $space-6 !important}
.ml6 { margin-left:   $space-6 !important}

.m7  { margin:        $space-7 !important}
.mt7 { margin-top:    $space-7 !important}
.mr7 { margin-right:  $space-7 !important}
.mb7 { margin-bottom: $space-7 !important}
.ml7 { margin-left:   $space-7 !important}

.m8  { margin:        $space-8 !important}
.mt8 { margin-top:    $space-8 !important}
.mr8 { margin-right:  $space-8 !important}
.mb8 { margin-bottom: $space-8 !important}
.ml8 { margin-left:   $space-8 !important}

.m9  { margin:        $space-9 !important}
.mt9 { margin-top:    $space-9 !important}
.mr9 { margin-right:  $space-9 !important}
.mb9 { margin-bottom: $space-9 !important}
.ml9 { margin-left:   $space-9 !important}

.m10  { margin:        $space-10 !important}
.mt10 { margin-top:    $space-10 !important}
.mr10 { margin-right:  $space-10 !important}
.mb10 { margin-bottom: $space-10 !important}
.ml10 { margin-left:   $space-10 !important}

.mxn1 { margin-left: -$space-1 !important; margin-right: -$space-1 !important; }
.mxn2 { margin-left: -$space-2 !important; margin-right: -$space-2 !important; }
.mxn3 { margin-left: -$space-3 !important; margin-right: -$space-3 !important; }
.mxn4 { margin-left: -$space-4 !important; margin-right: -$space-4 !important; }
.mxn5 { margin-left: -$space-5 !important; margin-right: -$space-5 !important; }
.mxn6 { margin-left: -$space-6 !important; margin-right: -$space-6 !important; }
.mxn7 { margin-left: -$space-7 !important; margin-right: -$space-7 !important; }
.mxn8 { margin-left: -$space-8 !important; margin-right: -$space-8 !important; }
.mxn9 { margin-left: -$space-9 !important; margin-right: -$space-9 !important; }
.mxn10 { margin-left: -$space-10 !important; margin-right: -$space-10 !important; }

.mx-auto { margin-left: auto; margin-right: auto; }
.p0 { padding: 0 !important}
.pb0 { padding-bottom: 0 !important; }

.p1  { padding:       $space-1 !important}
.py1 { padding-top:   $space-1 !important; padding-bottom: $space-1 !important}
.px1 { padding-left:  $space-1 !important; padding-right:  $space-1 !important}
.pl1 { padding-left:  $space-1 !important}
.pr1 { padding-right:  $space-1 !important}
.pb1 { padding-bottom: $space-1 !important;}

.p2  { padding:       $space-2 !important}
.py2 { padding-top:   $space-2 !important; padding-bottom: $space-2 !important}
.px2 { padding-left:  $space-2 !important; padding-right:  $space-2 !important}
.pl2 { padding-left:  $space-2 !important}
.pr2 { padding-right:  $space-2 !important}
.pb2 { padding-bottom: $space-2 !important; }

.p3  { padding:       $space-3 !important}
.py3 { padding-top:   $space-3 !important; padding-bottom: $space-3 !important}
.px3 { padding-left:  $space-3 !important; padding-right:  $space-3 !important}
.pl3 { padding-left:  $space-3 !important}
.pr3 { padding-right:  $space-3 !important}
.pb3 { padding-bottom: $space-3 !important;}

.p4  { padding:       $space-4 !important}
.py4 { padding-top:   $space-4 !important; padding-bottom: $space-4 !important}
.px4 { padding-left:  $space-4 !important; padding-right:  $space-4 !important}
.pl4 { padding-left:  $space-4 !important}
.pr4 { padding-right:  $space-4 !important}
.pb4 { padding-bottom: $space-4 !important; }

.p5  { padding:       $space-5 !important}
.py5 { padding-top:   $space-5 !important; padding-bottom: $space-5 !important}
.px5 { padding-left:  $space-5 !important; padding-right:  $space-5 !important}
.pl5 { padding-left:  $space-5 !important}
.pr5 { padding-right:  $space-5 !important}
.pb5 { padding-bottom: $space-5 !important; }

.p6  { padding:       $space-6 !important}
.py6 { padding-top:   $space-6 !important; padding-bottom: $space-6 !important}
.px6 { padding-left:  $space-6 !important; padding-right:  $space-6 !important}
.pl6 { padding-left:  $space-6 !important}
.pr6 { padding-right:  $space-6 !important}
.pb6 { padding-bottom: $space-6 !important; }

.p7  { padding:       $space-7 !important}
.py7 { padding-top:   $space-7 !important; padding-bottom: $space-7 !important}
.px7 { padding-left:  $space-7 !important; padding-right:  $space-7 !important}
.pl7 { padding-left:  $space-7 !important}
.pr7 { padding-right:  $space-7 !important}
.pb7 { padding-bottom: $space-7 !important; }

.p8  { padding:       $space-8 !important}
.py8 { padding-top:   $space-8 !important; padding-bottom: $space-8 !important}
.px8 { padding-left:  $space-8 !important; padding-right:  $space-8 !important}
.pl8 { padding-left:  $space-8 !important}
.pr8 { padding-right:  $space-8 !important}
.pb8 { padding-bottom: $space-8 !important; }

.p9  { padding:       $space-9 !important}
.py9 { padding-top:   $space-9 !important; padding-bottom: $space-9 !important}
.px9 { padding-left:  $space-9 !important; padding-right:  $space-9 !important}
.pl9 { padding-left:  $space-9 !important}
.pr9 { padding-right:  $space-9 !important}
.pb9 { padding-bottom: $space-9 !important; }

.p10  { padding:       $space-10 !important}
.py10 { padding-top:   $space-10 !important; padding-bottom: $space-10 !important}
.px10 { padding-left:  $space-10 !important; padding-right:  $space-10 !important}
.pl10 { padding-left:  $space-10 !important}
.pr10 { padding-right:  $space-10 !important}
.pb10 { padding-bottom: $space-10 !important; }

.pt1 { padding-top: $space-1 !important}
.pt2 { padding-top: $space-2 !important}
.pt3 { padding-top: $space-3 !important}
.pt4 { padding-top: $space-4 !important}
.pt5 { padding-top: $space-5 !important}
.pt6 { padding-top: $space-6 !important}
.pt7 { padding-top: $space-7 !important}
.pt8 { padding-top: $space-8 !important}
.pt9 { padding-top: $space-9 !important}
.pt10 { padding-top: $space-10 !important}

